import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import methodology from "../assets/images/components/services/methodology.svg";
import observability from "../assets/images/components/services/observability.svg";
import infrastructure from "../assets/images/components/services/infrastructure.svg";
import integration from "../assets/images/components/services/integration.svg";
import development from "../assets/images/components/services/development.svg";

const observabilityColor = "#00B894";
const infrastructureColor = "#FFA500";
const developmentColor = "#6C5CE7";
const integrationColor = "#CF6A87";
const methodologyColor = "#0A3D62";

export default function Services(): JSX.Element {
  const { t } = useTranslation();
  return (
    <section id="services" className="services section-bg">
      <Container data-aos="fade-up">
        <div className="section-title" style={{ marginBottom: "25px" }}>
          <h2>{t("services.title")}</h2>
        </div>

        <Row>
          <Col
            xl="4"
            md="6"
            className="d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <img src={observability} width="36px" alt={"observability"} />
              </div>
              <h4>
                <a href="#services" style={{ color: observabilityColor }}>
                  {t("services.obstitle")}
                </a>
              </h4>
              <p>
                <Trans i18nKey="services.obstext">
                  {t("services.inftitle")}
                </Trans>
              </p>
            </div>
          </Col>

          <Col
            xl="4"
            md="6"
            className="d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <img src={infrastructure} width="36px" alt={"infrastructure"} />
              </div>
              <h4>
                <a href="#services" style={{ color: infrastructureColor }}>
                  {t("services.inftitle")}
                </a>
              </h4>
              <p>{t("services.inftext")}</p>
            </div>
          </Col>

          <Col
            xl="4"
            md="6"
            className="d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <img src={development} width="36px" alt={"development"} />
              </div>
              <h4>
                <a href="#services" style={{ color: developmentColor }}>
                  {t("services.devtitle")}
                </a>
              </h4>
              <p>{t("services.devtext")}</p>
            </div>
          </Col>

          <Col
            xl={{ span: 4, offset: 2 }}
            md="6"
            className="d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <img src={integration} width="36px" alt={"integration"} />
              </div>
              <h4>
                <a href="/services#intergration" style={{ color: integrationColor }}>
                  {t("services.inttitle")}
                </a>
              </h4>
              <p>
                <Trans i18nKey="services.inttext">
                  Data exchange and transformation require to define an
                  integration strategy with an «End to End» vision through
                  several components.
                  <br />
                  (ESB, API, MFT, Automation, ETL, IPAAS)
                </Trans>
              </p>
            </div>
          </Col>

          <Col
            xl="4"
            md="6"
            className="d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <img src={methodology} width="36px" alt={"methodology"} />
              </div>
              <h4>
                <a href="#services" style={{ color: methodologyColor }}>
                  {t("services.methtitle")}
                </a>
              </h4>
              <p>{t("services.methtext")}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
