import React from "react";
import { Container, Row } from "react-bootstrap";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import bnp from "../assets/images/components/partners/bnp.png";
import cfm from "../assets/images/components/partners/cfm.png";
import disney from "../assets/images/components/partners/disneyland-paris.png";
import enedis from "../assets/images/components/partners/enedis.png";
import infogene from "../assets/images/components/partners/infogene.png";
import intm from "../assets/images/components/partners/intm.png";
import lesMousquetaires from "../assets/images/components/partners/les-mousquetaires.png";
import grdf from "../assets/images/components/partners/grdf.png";
import lOreal from "../assets/images/components/partners/l-oreal.png";
import servier from "../assets/images/components/partners/servier.png";
import systemeU from "../assets/images/components/partners/systeme-u.png";
import thales from "../assets/images/components/partners/thales.png";

import "swiper/swiper.scss";

export default function Partners(): JSX.Element {
  return (
    <section id="clients" className="clients section-bg">
      <Container>
        <Row data-aos="zoom-in">
          <Swiper
            modules={[Navigation, Autoplay]}
            breakpoints={{
              480: {
                slidesPerView: 3,
              },
              960: {
                slidesPerView: 5,
              },
            }}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
          >
            <SwiperSlide>
              <img src={bnp} alt="BNP Paribas" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={cfm} alt="CFM" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={disney} alt="Disneyland Paris" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={enedis} alt="Enedis" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={grdf} alt="GRDF" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={infogene} alt="Infogene" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={intm} alt="INTM groupe" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={lesMousquetaires} alt="Les Mousquetaires" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={lOreal} alt="L'Oréal" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={servier} alt="Servier" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={systemeU} alt="Système U" />
            </SwiperSlide>

            <SwiperSlide>
              <img src={thales} alt="Thalès" />
            </SwiperSlide>
          </Swiper>
        </Row>
      </Container>
    </section>
  );
}
