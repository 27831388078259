import React, { useState } from "react";
import { Container, Row, Col, Alert, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { appBasePath } from "../constants";
import { useTranslation } from "react-i18next";
import { GeoAltFill, EnvelopeFill, TelephoneFill, Linkedin } from "react-bootstrap-icons";

interface IFormInputs {
  name: string;
  email: number;
  subject: number;
  message: number;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  subject: yup.string().required(),
  message: yup.string().min(10).required(),
});

interface Message {
  variant: string;
  content: string;
}

const messageTimeout = 3000;

function ContactForm(): JSX.Element {
  const [message, setMessage] = useState<Message | null>(null);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFormInputs) => {
    axios
      .post(`${appBasePath}/api/contact`, {
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
      })
      .then(function () {
        reset();
        setMessage({
          content: t("contact.messageSent"),
          variant: "success",
        });
        setTimeout(() => setMessage(null), messageTimeout);
      })
      .catch(function () {
        reset();
        setMessage({
          content: t("contact.messageFail"),
          variant: "danger",
        });
        setTimeout(() => setMessage(null), messageTimeout);
      });
  };

  return (
    <section id="contact-us" className="contact">
      <Container data-aos="fade-up">
        <div className="section-title">
          <h2>Contact</h2>
        </div>

        <Row>
          <Col lg={5} className="d-flex align-items-stretch">
            <div className="info">
              <div className="address">
                <i>
                  <GeoAltFill />
                </i>
                <h4>{t("contact.address")}</h4>
                <a
                  href="https://goo.gl/maps/Fj1yBxjKTDnPWRkg6"
                  rel="noreferrer noopener"
                  target="_blank"
                >
S-Metro Building, 20th Floor, Suite 157
<p>725 Sukhumvit Road, Klongtan Nua Sub-district, Wattana District, Bangkok 10110</p>
                </a>
              </div>

              <div className="email">
                <i>
                  <EnvelopeFill />
                </i>
                <h4>{t("contact.email")}</h4>
                <a href="mailto:contact@eiffel-it.com">contact@eiffel-it.com</a>
              </div>

              <div className="phone">
                <i>
                  <TelephoneFill />
                </i>
                <h4>{t("contact.phone")}</h4>
                <a href="tel:+66808323230">+66 (0)8 08 32 32 30</a>
              </div>

              <div className="phone">
                <i>
                  <Linkedin />
                </i>
                <h4> LinkedIn </h4>
                <a href="https://www.linkedin.com/company/eiffel-it"
                rel="noreferrer noopener"
                target="_blank"
                >
                https://www.linkedin.com/company/eiffel-it</a>
              </div>

              <iframe title="location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.816258715482!2d100.56907871486825!3d13.729571501507095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f01060a5211%3A0x60bcbf2a471a184f!2sS-Metro%20Building%20(Metropolis%20Building)!5e0!3m2!1sfr!2sfr!4v1659613546979!5m2!1sfr!2sfr"
                frameBorder="0"
                style={{ border: "0", width: "100%", height: "290px" }}
                allowFullScreen
              ></iframe>
            </div>
          </Col>

          <Col lg={7} className="mt-5 mt-lg-0 d-flex align-items-stretch">
            <div className="email-form">
              <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                {message && (
                  <Alert variant={message.variant}>{message.content}</Alert>
                )}
                <Row>
                  <div className="form-group col-md-6">
                    <Form.Group controlId="validationName">
                      <Form.Control
                        type="text"
                        placeholder={t("contact.namePlaceholder")}
                        {...register("name")}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="form-group col-md-6">
                    <Form.Group controlId="validationEmail">
                      <Form.Control
                        type="email"
                        placeholder={t("contact.emailPlaceholder")}
                        {...register("email")}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Row>
                <div className="form-group">
                  <Form.Group controlId="validationSubject">
                    <Form.Control
                      type="text"
                      placeholder={t("contact.subjectPlaceholder")}
                      {...register("subject")}
                      isInvalid={!!errors.subject}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.subject?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="form-group">
                  <Form.Group controlId="validationMessage">
                    <Form.Control
                      as="textarea"
                      rows={15}
                      placeholder="Message"
                      {...register("message")}
                      isInvalid={!!errors.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.message?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button type="submit">{t("contact.sendButton")}</Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ContactForm;
