import React from "react";
import { useTranslation } from "react-i18next";

function Footer(): JSX.Element {
  const { t } = useTranslation();
  return (
    <footer
      id="footer"
      className="footer-bottom clearfix"
      style={{ textAlign: "center" }}
    >
      <p
        style={{
          marginBottom: "0",
          fontSize: "16px",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {t("footer.copyright")}
      </p>
    </footer>
  );
}

export default Footer;
